import React, { useContext, useEffect, useState } from "react";

import Button from "../view/component/button";
import AddProductStep1 from "../view/component/addProductStep1";
import AddProductStep2 from "../view/component/addProductStep2";
import AddProductStep3 from "../view/component/addProductStep3";
import AddProductStep4 from "../view/component/addProductStep4";
import AddProductStep5 from "../view/component/addProductStep5";
import AddProductStep6 from "../view/component/addProductStep6";

import { SessionContext } from "../util/context";
import { getDate, getDateISO, strings } from "../util/formatter";
import endpoint from "../config/endpoint";
import { request } from "../util/http";
import { isNotEmpty, validateDateFormat, validateFileType, validateLettersWithSpaces, validateNumbersOnly } from "../util/validator";
import { useNavigate } from "react-router-dom";

const AddProductModal = () =>
{
    const navigate = useNavigate();
    const { prefs, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [productAttributeList, setProductAttributeList] = useState([]);
    const [productInfo, setProductInfo] = useState({
        nameAR: "",
        nameEN: "",
        descriptionAR: "",
        descriptionEN: "",
        pagesCount: "",
        price: "",
        thumbnailURL: {},
        ISBN: "",
        producedAtDate: "",
        distributedAtDate: ""
    });
    const [productLang, setProductLang] = useState({});
    const [productCurrency, setProductCurrency] = useState({});
    const [productType, setProductType] = useState({});
    const [producingStatus, setProducingStatus] = useState({});
    const [distributingStatus, setDistributingStatus] = useState({});
    const [producer, setProducer] = useState({});
    const [selectedProducersList, setSelectedProducersList] = useState([]);
    const [producerSearchList, setProducerSearchList] = useState([]);
    const [producerSearchKey, setProducerSearchKey] = useState("");
    const [searchedForProducer, setSearchedForProducer] = useState(false);
    const [category, setCategory] = useState({});
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [contributor, setContributor] = useState({});
    const [selectedContributorsList, setSelectedContributorsList] = useState([]);
    const [contributorSearchList, setContributorSearchList] = useState([]);
    const [contributorSearchKey, setContributorSearchKey] = useState("");
    const [searchedForContributor, setSearchedForContributor] = useState(false);
    const [audienceGroup, setAudienceGroup] = useState({});
    const [selectedAudienceGroupList, setSelectedAudienceGroupList] = useState([]);

    const prev = () =>
    {
        setModalOptions({
            ...modalOptions,
            currentStep: modalOptions.currentStep - 1
        });
    };

    const next = () =>
    {
        if (modalOptions?.totalSteps === modalOptions?.currentStep)
        {
            createProduct();
        }
        else
        {
            setModalOptions({
                ...modalOptions,
                currentStep: modalOptions.currentStep + 1
            });
        }
    };

    const validateForm = () =>
    {
        if (modalOptions?.currentStep === 1 && isNotEmpty(productInfo?.nameAR) && validateLettersWithSpaces(productInfo?.nameAR, "ar", true)?.isValid && isNotEmpty(productInfo?.nameEN) && validateLettersWithSpaces(productInfo?.nameEN, "en", true)?.isValid &&
        isNotEmpty(productInfo?.descriptionAR) && validateLettersWithSpaces(productInfo?.descriptionAR, "ar", true, true)?.isValid && isNotEmpty(productInfo?.descriptionEN) && validateLettersWithSpaces(productInfo?.descriptionEN, "en", true, true)?.isValid && isNotEmpty(productInfo?.pagesCount) && validateNumbersOnly(productInfo?.pagesCount)?.isValid &&
        productLang?.value && isNotEmpty(productInfo?.price) && validateNumbersOnly(productInfo?.price, "decimal")?.isValid && productCurrency?.value && isNotEmpty(productInfo?.thumbnailURL?.name) &&
        validateFileType(productInfo?.thumbnailURL, ["image/png", "image/jpg", "image/jpeg"], ["png", "jpg", "jpeg"])?.isValid)
        {
            return false;
        }

        if (modalOptions?.currentStep === 2 && isNotEmpty(productInfo?.ISBN) && validateNumbersOnly(productInfo?.ISBN)?.isValid && productType?.value &&
        validateDateFormat(getDate(prefs?.lang, productInfo?.producedAtDate, "YYYY-MM-DD"))?.isValid && validateDateFormat(getDate(prefs?.lang, productInfo?.distributedAtDate, "YYYY-MM-DD"))?.isValid &&
        producingStatus?.value && distributingStatus?.value)
        {
            return false;
        }
        if (modalOptions?.currentStep === 3 && selectedProducersList?.length > 0)
        {
            return false;
        }

        if (modalOptions?.currentStep === 4 && selectedCategoryList?.length > 0)
        {
            return false;
        }

        if (modalOptions?.currentStep === 5 && selectedContributorsList?.length > 0)
        {
            return false
        }

        if (modalOptions?.currentStep === 6 && selectedAudienceGroupList?.length > 0)
        {
            return false
        }

        return true;
    }

    const createProduct = async () =>
    {
        setIsLoading(true);
        if (validateForm)
        {
            try
            {
                const data = {
                    post_format: "single",
                    name_ar: productInfo?.nameAR,
                    name_en: productInfo?.nameEN,
                    description_ar: productInfo?.descriptionAR,
                    description_en: productInfo?.descriptionEN,
                    pages_count: productInfo?.pagesCount,
                    lang_code: productLang?.value,
                    price: productInfo?.price,
                    currency: productCurrency?.value,
                    thumbnail_url: productInfo?.thumbnailURL,
                    global_identifier: productInfo?.ISBN,
                    form_code: productType?.value,
                    ...(productType?.value === "ea" && { form_details_code: "epub" }),
                    produced_at: getDateISO(getDate("en", productInfo?.producedAtDate, "YYYY-MM-DD")),
                    distributed_at: getDateISO(getDate("en", productInfo?.distributedAtDate, "YYYY-MM-DD")),
                    publishing_status_code: producingStatus?.value,
                    distribution_status_code: distributingStatus?.value,
                    producer: JSON.stringify(selectedProducersList?.map(item => (item.id))),
                    subject: JSON.stringify(selectedCategoryList?.map(item => (item.value))),
                    contributor: JSON.stringify(selectedContributorsList?.map(item => ({ id: item.id, role: item.type.value }))),
                    audience: JSON.stringify(selectedAudienceGroupList?.map(item => (item.value))),
                    outlet: JSON.stringify([])
                };

                const res = await request("POST", endpoint.bms.product.data, data);

                setIsLoading(false);
                setActiveModal(undefined)
                setModalOptions({});
                setTimeout(() =>
                {
                    navigate(0);
                }, 1000);
            }
            catch (error)
            {
                console.log(error?.error_msg);
                setIsLoading(false);
            }
        }
    }

    const fetchProductAttributeList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", endpoint.bms.product.attribute);
            setProductAttributeList(res?.list);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    const fetchProducerSearch = async () =>
    {
        const res = await request("GET", `${endpoint.bms.producer.search}?keyword=${producerSearchKey}`);
        setProducerSearchList(res?.list);
        setSearchedForProducer(true);
        setIsLoading(false);
    };

    const fetchContributorSearch = async () =>
    {
        const res = await request("GET", `${endpoint.bms.contributor.search}?keyword=${contributorSearchKey}`);
        setContributorSearchList(res?.list);
        setSearchedForContributor(true);
        setIsLoading(false);
    };

    useEffect(() =>
    {
        if (producerSearchKey?.length > 2)
        {
            setIsLoading(true);
            try
            {
                fetchProducerSearch();
            }
            catch (error) {
                setIsLoading(false);
            }
        }
    }, [producerSearchKey]);

    useEffect(() =>
    {
        if (contributorSearchKey?.length > 2)
        {
            setIsLoading(true);
            try
            {
                fetchContributorSearch();
            }
            catch (error) {
                setIsLoading(false);
            }
        }
    }, [contributorSearchKey]);

    useEffect(() =>
    {
        fetchProductAttributeList();
    }, []);

    return (
        <div className="p-grid-md">
            {modalOptions.currentStep === 1
                ? <AddProductStep1
                    productInfo={productInfo}
                    productLang={productLang}
                    productCurrency={productCurrency}
                    setProductInfo={setProductInfo}
                    setProductLang={setProductLang}
                    setProductCurrency={setProductCurrency}
                />
                : modalOptions.currentStep === 2
                    ? <AddProductStep2
                        productAttributeList={productAttributeList}
                        productInfo={productInfo}
                        productType={productType}
                        producingStatus={producingStatus}
                        distributingStatus={distributingStatus}
                        setProductInfo={setProductInfo}
                        setProductType={setProductType}
                        setProducingStatus={setProducingStatus}
                        setDistributingStatus={setDistributingStatus}
                    />
                    : modalOptions.currentStep === 3
                        ? <AddProductStep3
                            isLoading={isLoading}
                            searchedForProducer={searchedForProducer}
                            producer={producer}
                            selectedProducersList={selectedProducersList}
                            producerSearchList={producerSearchList}
                            producerSearchKey={producerSearchKey}
                            setSearchedForProducer={setSearchedForProducer}
                            setProducer={setProducer}
                            setSelectedProducersList={setSelectedProducersList}
                            setProducerSearchList={setProducerSearchList}
                            setProducerSearchKey={setProducerSearchKey}
                        />
                        : modalOptions.currentStep === 4
                            ? <AddProductStep4
                                category={category}
                                productAttributeList={productAttributeList}
                                selectedCategoryList={selectedCategoryList}
                                setCategory={setCategory}
                                setSelectedCategoryList={setSelectedCategoryList}
                            />
                            : modalOptions.currentStep === 5
                            ? <AddProductStep5
                                isLoading={isLoading}
                                searchedForContributor={searchedForContributor}
                                productAttributeList={productAttributeList}
                                contributor={contributor}
                                selectedContributorsList={selectedContributorsList}
                                contributorSearchList={contributorSearchList}
                                contributorSearchKey={contributorSearchKey}
                                setSearchedForContributor={setSearchedForContributor}
                                setContributor={setContributor}
                                setSelectedContributorsList={setSelectedContributorsList}
                                setContributorSearchList={setContributorSearchList}
                                setContributorSearchKey={setContributorSearchKey}
                            />
                            : <AddProductStep6
                                audienceGroup={audienceGroup}
                                productAttributeList={productAttributeList}
                                selectedAudienceGroupList={selectedAudienceGroupList}
                                setAudienceGroup={setAudienceGroup}
                                setSelectedAudienceGroupList={setSelectedAudienceGroupList}
                            />
            }
            <div className={`flex ${modalOptions.currentStep !== 1 ? "justify-between" : "justify-end"} pt-grid-lg`}>
                {modalOptions.currentStep !== 1 &&
                    <Button
                        text={strings("button.previous")}
                        padding="py-2 px-grid-xl"
                        onClick={prev}
                    />
                }
                <Button
                    disabled={isLoading || validateForm()}
                    text={strings("button.next")}
                    bgColor={"bg-neutral-low-darkest"}
                    hoverBGColor={"hover:bg-transparent"}
                    textColor={"text-neutral-high-lightest"}
                    hoverTextColor={"hover:text-neutral-low-darkest"}
                    hoverBorder={"hover:border-2 hover:border-neutral-low-darkest"}
                    border={"border-2 border-transparent"}
                    padding="py-2 px-grid-xl"
                    onClick={next}
                />
            </div>
        </div>
    );
};

export default AddProductModal;