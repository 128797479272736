import React, { useContext, useState } from "react";

import LineMsg from "./lineMsg";
import RemovableItemWithDropdown from "./removableItemWithDropdown";

import { strings } from "../../util/formatter";
import constant from "../../config/constant";
import { SessionContext } from "../../util/context";
import SearchableDropdownMenu from "./searchableDropdownMenu";

const AddProductStep5 = (props) =>
{
    const { prefs } = useContext(SessionContext);

    const {
        isLoading,
        searchedForContributor,
        productAttributeList,
        contributor,
        selectedContributorsList,
        contributorSearchList,
        contributorSearchKey,
        setSearchedForContributor,
        setContributor,
        setSelectedContributorsList,
        setContributorSearchList,
        setContributorSearchKey
    } = props;

    const addContributor = (item) =>
    {
        setContributor({});
        if (!(selectedContributorsList?.some(contributor => contributor?.value === item.value)))
        {
            const tempItem = item
            if (!tempItem?.type?.value)
            {
                tempItem.type = productAttributeList?.filter(item => item?.type === "contributor_role" && item?.value === "a01")?.[0];
                return setSelectedContributorsList([...selectedContributorsList, tempItem]);
            }
            setSelectedContributorsList([...selectedContributorsList, item]);
        }
    };

    const removeContributor = (item) =>
    {
        const temp = selectedContributorsList;
        const arr = temp?.filter(contributor => contributor?.value !== item.value);
        setSelectedContributorsList([...arr]);
    };

    const addContributorType = (type, item) =>
    {
        const temp = selectedContributorsList;
        const foundItem = temp?.find(contributor => contributor?.value === item.value);

        if (foundItem)
        {
            foundItem.type = type;
            setSelectedContributorsList([...temp]);
        }
    };

    return (
        <>
            <div className={`w-full pb-grid-sm`}>
                <SearchableDropdownMenu
                    isLoading={isLoading}
                    searched={searchedForContributor}
                    list={contributorSearchList}
                    labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                    searchKey={contributorSearchKey}
                    placeholder={strings("placeholder.selectContributor")}
                    selectedValue={contributor}
                    setSearched={setSearchedForContributor}
                    setList={setContributorSearchList}
                    setSearchKey={setContributorSearchKey}
                    setSelected={addContributor}
                />
                <LineMsg type="error"/>
            </div>
            {selectedContributorsList?.length > 0 && <div className="h-[1px] mb-grid-sm bg-primary-light"/>}
            <div>
                {selectedContributorsList?.length > 0 &&
                    selectedContributorsList?.map((item, index) =>
                    {
                        return (
                            <RemovableItemWithDropdown
                                key={index}
                                index={index}
                                item={item}
                                placeholder={strings("placeholder.contributorType")}
                                labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                                selected={item?.type?.type ? item?.type : productAttributeList?.filter(item => item?.type === "contributor_role" && item?.value === "a01")?.[0]}
                                dropdownList={productAttributeList?.filter(item => item?.type === "contributor_role")}
                                dropdownLabel={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                                listLength={selectedContributorsList?.length}
                                addValue={(e) => addContributorType(e, item)}
                                removeItem={() => removeContributor(item)}
                            />
                        ) })
                }
            </div>
        </>
    );
};

export default AddProductStep5;