import React, { useContext, useState, createRef } from "react";

import Button from "../view/component/button";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Label from "../view/component/label";
import FileInput from "../view/component/fileInput";

import endpoint from "../config/endpoint";
import { isNotEmpty, validateFileType, validateLettersWithSpaces } from "../util/validator";
import { SessionContext } from "../util/context";
import { request } from "../util/http";
import { strings } from "../util/formatter";
import { showNotification } from "../util/notification";

const AddContributorModal = () =>
{
    const { prefs, setActiveModal, setModalOptions } = useContext(SessionContext);
    const thumbnailURLRef = createRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [contributorInfo, setContributorInfo] = useState({
        nameAR: "",
        nameEN: "",
        thumbnailURL: {}
    });

    const {
        nameAR,
        nameEN,
        thumbnailURL
    } = contributorInfo;

    const selectThumbnail = () =>
    {
        if (thumbnailURLRef.current !== null)
        {
            thumbnailURLRef.current.click();
        }
    };

    const setContributorInfoForm = (value, key) =>
    {
        setContributorInfo({ ...contributorInfo, [key]: value });
    };

    const next = () =>
    {
        createContributor();
    };

    const validateForm = () =>
    {
        if (isNotEmpty(nameAR) && validateLettersWithSpaces(nameAR, "ar", true)?.isValid && isNotEmpty(nameEN) && validateLettersWithSpaces(nameEN, "en", true)?.isValid &&
        isNotEmpty(thumbnailURL?.name) && validateFileType(thumbnailURL, ["image/png", "image/jpg", "image/jpeg"], ["png", "jpg", "jpeg"])?.isValid)
        {
            return false;
        }

        return true;
    }

    const createContributor = async () =>
    {
        setIsLoading(true);
        if (validateForm)
        {
            try
            {
                const data = {
                    name_ar: nameAR,
                    name_en: nameEN,
                    thumbnail_url: thumbnailURL
                };

                const res = await request("POST", endpoint.bms.contributor.data, data);

                showNotification("success", strings("notificationMessage.addContributor"), strings("notificationMessage.successAddContributor"), prefs?.dir);
                setIsLoading(false);
                setActiveModal(undefined)
                setModalOptions({});
            }
            catch (error)
            {
                showNotification("error", strings("message.error"), error?.error_msg, prefs?.dir);
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="p-grid-md">
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameAR")}/>
                    <Input
                        name="nameAR"
                        value={contributorInfo?.nameAR}
                        setValue={(a) => setContributorInfoForm(a, "nameAR")}
                    />
                    <LineMsg msg={validateLettersWithSpaces(nameAR, "ar", true)?.msg} type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameEN")}/>
                    <Input
                        name="nameEN"
                        value={contributorInfo?.nameEN}
                        setValue={(a) => setContributorInfoForm(a, "nameEN")}
                    />
                    <LineMsg msg={validateLettersWithSpaces(nameEN, "en", true)?.msg} type="error"/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.coverImage")}/>
                <FileInput
                    fileRef={thumbnailURLRef}
                    value={contributorInfo?.thumbnailURL}
                    setValue={(a) => setContributorInfoForm(a, "thumbnailURL")}
                    selectFile={selectThumbnail}
                />
                <LineMsg type="error"/>
            </div>
            <div className={`flex justify-end pt-grid-lg`}>
                <Button
                    disabled={isLoading || validateForm()}
                    text={strings("button.next")}
                    bgColor={"bg-neutral-low-darkest"}
                    hoverBGColor={"hover:bg-transparent"}
                    textColor={"text-neutral-high-lightest"}
                    hoverTextColor={"hover:text-neutral-low-darkest"}
                    hoverBorder={"hover:border-2 hover:border-neutral-low-darkest"}
                    border={"border-2 border-transparent"}
                    padding="py-2 px-grid-xl"
                    onClick={next}
                />
            </div>
        </div>
    );
};

export default AddContributorModal;