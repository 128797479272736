import React, { useContext } from "react";

import LineMsg from "./lineMsg";
import RemovableItemWithDropdown from "./removableItemWithDropdown";
import SearchableDropdownMenu from "./searchableDropdownMenu";

import { strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";
import constant from "../../config/constant";

export const PRODUCER = [
    {
        label: "دار ميلاد للنشر والتوزيع",
        value: "melad"
    },
    {
        label: "دار تكوين للنشر والتوزيع",
        value: "takween"
    }
];

const AddProductStep3 = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const {
        isLoading,
        searchedForProducer,
        producer,
        setSearchedForProducer,
        setProducer,
        selectedProducersList,
        producerSearchList,
        producerSearchKey,
        setSelectedProducersList,
        setProducerSearchList,
        setProducerSearchKey
    } = props;

    const addProducer = (item) =>
    {
        setProducer({});
        if (!(selectedProducersList?.some(producer => producer?.value === item.value)))
        {
            setSelectedProducersList([...selectedProducersList, item]);
        }
    };

    const removeProducer = (item) =>
    {
        const temp = selectedProducersList;
        const arr = temp?.filter(producer => producer?.value !== item.value);
        setSelectedProducersList([...arr]);
    };

    return (
        <>
            <div className="w-full pb-grid-sm">
                <SearchableDropdownMenu
                    isLoading={isLoading}
                    searched={searchedForProducer}
                    list={producerSearchList}
                    labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                    searchKey={producerSearchKey}
                    placeholder={strings("placeholder.selectProducer")}
                    selectedValue={producer}
                    setSearched={setSearchedForProducer}
                    setList={setProducerSearchList}
                    setSearchKey={setProducerSearchKey}
                    setSelected={addProducer}
                />
                <LineMsg type="error"/>
            </div>
            {selectedProducersList?.length > 0 && <div className="h-[1px] mb-grid-sm bg-primary-light"/>}
            <div>
                {selectedProducersList?.length > 0 &&
                    selectedProducersList?.map((producer, index) =>
                        (
                            <RemovableItemWithDropdown
                                shouldShouldDropdown={false}
                                key={index}
                                index={index}
                                item={producer}
                                labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                                listLength={selectedProducersList?.length}
                                removeItem={() => removeProducer(producer)}
                            />
                        ))
                }
            </div>
        </>
    );
};

export default AddProductStep3;