import React, { useContext } from "react";

import { SessionContext } from "../../util/context";

const Textarea = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const { name, value, placeholder, setValue } = props;

    return (
        <textarea
            onChange={(e) => setValue(e.target.value)}
            name={name}
            value={value}
            rows={5}
            placeholder={placeholder}
            className={`w-full py-grid-sm px-grid-sm resize-none ${isPostLogin ? "bg-neutral-high-regular text-neutral-low-darkest placeholder:text-neutral-low-darkest/50 border-2 border-transparent focus:border-primary-regular" : "bg-secondary-light text-secondary-darkest placeholder:text-secondary-darkest/50"} text-sm rounded-xs focus:outline-none transition-colors duration-fast ease-in`}
        />
    );
};

export default Textarea;