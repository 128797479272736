import React, { useContext } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";
import DatePickerButton from "./datePickerButton";

import constant from "../../config/constant";
import { getDate, strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";
import { validateDateFormat, validateNumbersOnly } from "../../util/validator";

const AddProductStep2 = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const {
        productAttributeList,
        productInfo,
        productType,
        producingStatus,
        distributingStatus,
        setProductInfo,
        setProductType,
        setProducingStatus,
        setDistributingStatus
    } = props;

    const {
        ISBN,
        producedAtDate,
        distributedAtDate
    } = productInfo;

    const setProductInfoForm = (value, key) =>
    {
        setProductInfo({ ...productInfo, [key]: value });
    };

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.isbn")}/>
                <Input
                    name="ISBN"
                    value={ISBN}
                    setValue={(a) => setProductInfoForm(a, "ISBN")}
                />
                <LineMsg msg={validateNumbersOnly(ISBN)?.msg} type="error"/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.productType")}/>
                <DropdownMenu
                    list={productAttributeList?.filter(item => item?.type === "form")}
                    labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                    placeholder={strings("placeholder.selectProductType")}
                    selectedValue={productType}
                    setSelected={setProductType}
                />
                <LineMsg type="error"/>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.producedAt")}/>
                    <DatePickerButton
                        value={producedAtDate}
                        setValue={(a) => setProductInfoForm(a, "producedAtDate")}
                    />
                    <LineMsg msg={validateDateFormat(getDate(prefs?.lang, producedAtDate, "YYYY-MM-DD"))?.msg} type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.distributedAt")}/>
                    <DatePickerButton
                        value={distributedAtDate}
                        setValue={(a) => setProductInfoForm(a, "distributedAtDate")}
                    />
                    <LineMsg msg={validateDateFormat(getDate(prefs?.lang, distributedAtDate, "YYYY-MM-DD"))?.msg} type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.producingStatus")}/>
                    <DropdownMenu
                        list={productAttributeList?.filter(item => item?.type === "publishing_status")}
                        labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                        placeholder={strings("placeholder.selectProductType")}
                        selectedValue={producingStatus}
                        setSelected={setProducingStatus}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.distributingStatus")}/>
                    <DropdownMenu
                        list={productAttributeList?.filter(item => item?.type === "distribution_status")}
                        labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                        placeholder={strings("placeholder.selectAudienceGroup")}
                        selectedValue={distributingStatus}
                        setSelected={setDistributingStatus}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
        </>
    );
};

export default AddProductStep2;