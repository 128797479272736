import { strings } from "./formatter";

export const validateLettersOnly = (value, language = undefined, allowNumbers = false, allowSymbols = false) =>
{
    if (!value)
    {
        return { isValid: true, msg: "" };
    }

    const arabicRegex =
    allowNumbers && allowSymbols
    ? /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF0-9()"?,،.!:]+$/
    : allowSymbols
    ? /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF()"?,،.!:]+$/
    : allowNumbers
    ? /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF0-9]+$/
    : /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF]+$/;
    const englishRegex =
    allowNumbers && allowSymbols
    ? /^[a-zA-Z0-9()"?,،.!:]+$/
    : allowSymbols
    ? /^[a-zA-Z()"?,،.!:]+$/
    : allowNumbers
    ? /^[a-zA-Z0-9]+$/
    : /^[a-zA-Z]+$/;

    if (language === "ar" && !arabicRegex.test(value))
    {
        return { isValid: false, msg: strings("message.arabicLettersOnly") };
    }
    else if (language === "en" && !englishRegex.test(value))
    {
        return { isValid: false, msg: strings("message.englishLettersOnly") };
    }
    else if (!language)
    {
        const containsArabic = arabicRegex.test(value);
        const containsEnglish = englishRegex.test(value);

        if (containsArabic && containsEnglish)
        {
            return { isValid: false, msg: strings("message.singleLanguage") };
        }
        if (!arabicRegex.test(value) && !englishRegex.test(value))
        {
            return {
                isValid: false,
                msg: allowNumbers ? strings("message.lettersAndNumbersOnly") : strings("message.lettersOnly")
            };
        }
    }

    return { isValid: true, msg: "" };
};

export const validateLettersWithSpaces = (value, language = undefined, allowNumbers = false, allowSymbols = false) => {
    if (!value)
    {
        return { isValid: true, msg: "" };
    }

    const arabicRegex =
    allowNumbers && allowSymbols
    ? /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF0-9()"?,،.!:]+(?: [\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF0-9()"?,،.!:]+)*$/
    : allowSymbols
    ? /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF()"?,،.!:]+(?: [\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF()"?,،.!:]+)*$/
    : allowNumbers
    ? /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF0-9]+(?: [\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF0-9]+)*$/
    : /^[\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF]+(?: [\u0621-\u064A\u066E-\u066F\u0671-\u06D3\u06D5\u06FA-\u06FF]+)*$/;
    const englishRegex =
    allowNumbers && allowSymbols
    ? /^[a-zA-Z0-9()"?,،.!:]+(?: [a-zA-Z0-9()"?,،.!:]+)*$/
    : allowSymbols
    ? /^[a-zA-Z()"?,،.!:]+(?: [a-zA-Z()"?,،.!:]+)*$/
    : allowNumbers
        ? /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/
        : /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;

    if (language === "ar" && !arabicRegex.test(value))
    {
        return { isValid: false, msg: strings("message.arabicLettersOnly") };
    }
    else if (language === "en" && !englishRegex.test(value))
    {
        return { isValid: false, msg: strings("message.englishLettersOnly") };
    }
    else if (!language)
    {
        if (!arabicRegex.test(value) && !englishRegex.test(value))
        {
            return {
                isValid: false,
                msg: allowNumbers ? strings("message.lettersAndNumbersOnly") : strings("message.lettersOnly")
            };
        }
    }

    return { isValid: true, msg: "" };
};

export const validateNumbersOnly = (value, type = "int") =>
{
    if (!value) {
        return {
            isValid: true,
            msg: ""
        };
    }

    const integerRegex = /^\d+$/;
    const decimalRegex = /^\d+(\.\d+)?$/;

    let regex;
    if (type === "int") {
        regex = integerRegex;
    }
    else
    {
        regex = decimalRegex;
    }

    if (!regex.test(value)) {
        return {
            isValid: false,
            msg: strings("message.numbersOnly")
        };
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const validateAmount = (value, type = "int", limit = 6) => {
    if (!value) {
        return {
            isValid: true,
            msg: ""
        };
    }

    const integerRegex = new RegExp(`^[1-9]\\d{0,${limit - 1}}$`);
    const decimalRegex = new RegExp(`^[1-9]\\d{0,${limit - 1}}(\\.\\d{1,2})?$`);

    let regex;
    if (type === "int") {
        regex = integerRegex;
    } else {
        regex = decimalRegex;
    }

    if (!regex.test(value)) {
        return {
            isValid: false,
            msg: strings("message.invalidAmount")
        };
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const validateDateFormat = (value) =>
{
    if (!value)
    {
        return {
            isValid: true,
            msg: ""
        };
    }

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateRegex.test(value))
    {
        return {
            isValid: false,
            msg: strings("message.invalidDateFormat")
        };
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const validateFutureDate = (value) =>
{
    if (!value)
    {
        return {
            isValid: true,
            msg: ""
        };
    }

    const inputDate = new Date(value);
    const currentDate = new Date();

    if (inputDate <= currentDate)
    {
        return {
            isValid: false,
            msg: strings("message.dateInFuture")
        };
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const validateEmail = (value) =>
{
    if (!value)
    {
        return {
            isValid: true,
            msg: ""
        };
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(value))
    {
        return {
            isValid: false,
            msg: strings("message.invalidEmail")
        };
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const validatePhoneNumber = (value, countryCode = "966") =>
{
    if (!value)
    {
        return {
            isValid: true,
            msg: ""
        };
    }

    const saudiRegex = /^5\d{8}$/;
    const otherCountries = /^\d+$/;

    if (countryCode === "966")
    {
        if (!saudiRegex.test(value))
        {
            return {
                isValid: false,
                msg: strings("message.invalidPhone")
            };
        }
    }
    else
    {
        if (!otherCountries.test(value))
        {
            return {
                isValid: false,
                msg: strings("message.invalidPhone")
            };
        }
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const validatePassword = (value) =>
{
    if (!value)
    {
        return {
            isValid: true,
            msg: ""
        };
    }

    // Password conditions:
    // - At least 8 characters
    // - At least 1 uppercase letter
    // - At least 1 lowercase letter
    // - At least 1 number
    // - At least 1 special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    if (!passwordRegex.test(value))
    {
        return {
            isValid: false,
            msg: strings("message.invalidPassword")
        };
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const validateSameLanguage = (string1, string2) =>
{
    if (!string1 || !string2)
    {
        return {
            isValid: true,
            msg: ""
        };
    }

    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
    const englishRegex = /[a-zA-Z]/;

    const isArabic1 = arabicRegex.test(string1);
    const isArabic2 = arabicRegex.test(string2);
    const isEnglish1 = englishRegex.test(string1);
    const isEnglish2 = englishRegex.test(string2);

    if ((isArabic1 && isArabic2) || (isEnglish1 && isEnglish2))
    {
        return {
            isValid: true,
            msg: ""
        };
    }

    return {
        isValid: false,
        msg: strings("message.sameLanguage")
    };
};

export const validateFileType = (file, allowedTypes, placeholders) =>
{
    if (!file?.name)
    {
        return {
            isValid: true,
            msg: ""
        };
    }
    if (!allowedTypes?.includes(file.type))
    {
        return {
            isValid: false,
            msg: strings("message.invalidFileType")?.replace("{TYPES}", placeholders?.join(", "))
        };
    }

    return {
        isValid: true,
        msg: ""
    };
};

export const isNotEmpty = (value) =>
{
    if (value)
    {
        return true;
    }

    return false;
}

export const isTuesday = (date) =>
{
    return date.getDay() === 2;
};