const endpoint = {
    bms:
    {
        contributor:
        {
            data: "bms/contributor/data",
            list: "bms/contributor/list",
            search: "bms/contributor/search"
        },
        outlet:
        {
            data: "bms/outlet/data",
            list: "bms/outlet/list"
        },
        exporter:
        {
            product: "bms/exporter/product"
        },
        producer:
        {
            data: "bms/producer/data",
            list: "bms/producer/list",
            search: "bms/producer/search"
        },
        product:
        {
            attribute: "bms/product/attribute",
            data: "bms/product/data",
            list: "bms/product/list",
            search: "bms/product/search"
        },
        leaderboard:
        {
            list: "bms/leaderboard/list"
        },
        overview:
        {
            data: "bms/overview/data"
        }
    },
    cms:
    {
        announcement:
        {
            list : "cms/announcement/list"
        },
        bank:
        {
            list : "cms/bank/list"
        },
        country:
        {
            list : "cms/country/list"
        },
        currency:
        {
            list : "cms/currency/list"
        },
        faq:
        {
            list : "cms/faq/list"
        },
        page:
        {
            data : "cms/page/data"
        },
        pricing:
        {
            data : "cms/pricing/data"
        }
    },
    crm:
    {
        authorization:
        {
            email: "crm/authorization/email",
            sms: "crm/authorization/sms"
        },
        merchant:
        {
            apiKey: "crm/merchant/apiKey",
            cr: "crm/merchant/cr",
            data: "crm/merchant/data",
            subscription: "crm/merchant/subscription",
            user: "crm/merchant/user"
        },
        user:
        {
            access: "crm/user/access",
            avatar: "crm/user/avatar",
            data: "crm/user/data",
            invitation: "crm/user/invitation",
            merchant: "crm/user/merchant",
            password: "crm/user/password",
            token: "crm/user/token"
        }
    },
    fms:
    {
        bankAccount:
        {
            data: "fms/bankAccount/data",
            list: "fms/bankAccount/list"
        },
        credit:
        {
            redirection: "fms/credit/redirection"
        },
        debit:
        {
            directDeposit: "fms/debit/directDeposit"
        },
        history:
        {
            balance: "fms/history/balance"
        },
        wallet:
        {
            data: "fms/wallet/data"
        }
    }
};

export default endpoint;