import React, { createRef } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import Textarea from "./textarea";
import DropdownMenu from "./dropdownMenu";
import FileInput from "./fileInput";

import { strings } from "../../util/formatter";
import { validateLettersWithSpaces, validateNumbersOnly } from "../../util/validator";

export const LANGUAGE = [
    {
        label: strings("language.ar"),
        value: "ar"
    },
    {
        label: strings("language.en"),
        value: "en"
    }
];

export const CURRENCY = [
    {
        label: strings("currencyOption.sar"),
        value: "sar"
    }
];

const AddProductStep1 = (props) =>
{
    const thumbnailURLRef = createRef(null);
    const {
        productInfo,
        productLang,
        productCurrency,
        setProductInfo,
        setProductLang,
        setProductCurrency
    } = props;
    const {
        nameAR,
        nameEN,
        descriptionAR,
        descriptionEN,
        pagesCount,
        price,
        thumbnailURL
    } = productInfo;

    const selectCover = () =>
    {
        if (thumbnailURLRef.current !== null)
        {
            thumbnailURLRef.current.click();
        }
    };

    const setProductInfoForm = (value, key) =>
    {
        setProductInfo({ ...productInfo, [key]: value });
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameAR")}/>
                    <Input
                        name="nameAR"
                        value={nameAR}
                        setValue={(a) => setProductInfoForm(a, "nameAR")}
                    />
                    <LineMsg msg={validateLettersWithSpaces(nameAR, "ar", true)?.msg} type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.nameEN")}/>
                    <Input
                        name="nameEN"
                        value={nameEN}
                        setValue={(a) => setProductInfoForm(a, "nameEN")}
                    />
                    <LineMsg msg={validateLettersWithSpaces(nameEN, "en", true)?.msg} type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.descriptionAR")}/>
                    <Textarea
                        name="descriptionAR"
                        value={descriptionAR}
                        setValue={(a) => setProductInfoForm(a, "descriptionAR")}
                    />
                    <LineMsg msg={validateLettersWithSpaces(descriptionAR, "ar", true, true)?.msg} type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.descriptionEN")}/>
                    <Textarea
                        name="descriptionEN"
                        value={descriptionEN}
                        setValue={(a) => setProductInfoForm(a, "descriptionEN")}
                    />
                    <LineMsg msg={validateLettersWithSpaces(descriptionEN, "en", true, true)?.msg} type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.pagesCount")}/>
                    <Input
                        name="pagesCount"
                        value={pagesCount}
                        setValue={(a) => setProductInfoForm(a, "pagesCount")}
                    />
                    <LineMsg msg={validateNumbersOnly(pagesCount)?.msg} type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.productLang")}/>
                    <DropdownMenu
                        list={LANGUAGE}
                        labelKey="label"
                        placeholder={strings("placeholder.selectLang")}
                        selectedValue={productLang}
                        setSelected={setProductLang}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.price")}/>
                    <Input
                        name="price"
                        value={price}
                        setValue={(a) => setProductInfoForm(a, "price")}
                    />
                    <LineMsg msg={validateNumbersOnly(price, "decimal")?.msg} type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.currency")}/>
                    <DropdownMenu
                        list={CURRENCY}
                        labelKey="label"
                        placeholder={strings("placeholder.selectCurrency")}
                        selectedValue={productCurrency}
                        setSelected={setProductCurrency}
                    />
                    <LineMsg type="error"/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.coverImage")}/>
                <FileInput
                    fileRef={thumbnailURLRef}
                    value={thumbnailURL}
                    setValue={(a) => setProductInfoForm(a, "thumbnailURL")}
                    selectFile={selectCover}
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default AddProductStep1;