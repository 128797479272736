import React, { useContext } from "react";

import DropdownMenu from "./dropdownMenu";
import RemovableItemWithDropdown from "./removableItemWithDropdown";
import LineMsg from "./lineMsg";

import { strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";
import constant from "../../config/constant";

const AddProductStep4 = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const {
        category,
        productAttributeList,
        selectedCategoryList,
        setCategory,
        setSelectedCategoryList
    } = props

    const addCategory = (item) =>
    {
        setCategory({});
        if (!(selectedCategoryList?.some(category => category.value === item.value)))
        {
            setSelectedCategoryList([...selectedCategoryList, item]);
        }
    };

    const removeCategory = (item) =>
    {
        const temp = selectedCategoryList;
        const arr = temp?.filter(category => category.value !== item.value);
        setSelectedCategoryList([...arr]);
    };

    return (
        <>
            <div className={`w-full pb-grid-sm`}>
                <DropdownMenu
                    list={productAttributeList?.filter(item => item?.type === "subject")}
                    labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                    placeholder={strings("placeholder.selectCategory")}
                    selectedValue={category}
                    setSelected={addCategory}
                />
                <LineMsg type="error"/>
            </div>
            {selectedCategoryList?.length > 0 && <div className="h-[1px] mb-grid-sm bg-primary-light"/>}
            <div>
                {selectedCategoryList?.length > 0 &&
                    selectedCategoryList?.map((category, index) =>
                        (
                            <RemovableItemWithDropdown
                                shouldShouldDropdown={false}
                                key={index}
                                index={index}
                                item={category}
                                labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                                listLength={selectedCategoryList?.length}
                                removeItem={() => removeCategory(category)}
                            />
                        ))
                }
            </div>
        </>
    );
};

export default AddProductStep4;