import React, { useContext, useEffect, useState } from "react";

import dayjs from "dayjs";

import DateRange from "../view/component/dateRange";
import DataCardWithIcon from "../view/component/dataCardWithIcon";
import DataCardPercentage from "../view/component/dataCardPercentage";
import PieChartView from "../view/component/pieChartView";
import BarChartView from "../view/component/barChartView";
import LineChartView from "../view/component/lineChartView";
import DropdownMenu from "../view/component/dropdownMenu";
import DateRangeByFilter from "../view/component/dateRangeByFilter";

import constant from "../config/constant";
import endpoint from "../config/endpoint";
import { getDate, getPreviousTuesday, numberToNumberWithCommas, priceToPriceWithCurrency, strings } from "../util/formatter";
import { request } from "../util/http";
import { SessionContext } from "../util/context";
import { isTuesday } from "../util/validator";

const Overview = () =>
{
    const { prefs } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState(constant.date_range[0]);
    const [date, setDate] = useState(dayjs(getPreviousTuesday())?.toDate());
    const [overviewData, setOverviewData] = useState({});

    const fetchOverviewData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.overview.data}?period=${getDate(prefs?.lang, date, "YYYY-MM-DD")}&period_code=${dateRangeValue?.value}`);
            setOverviewData(res?.info)
            setIsLoading(false);
        }
        catch (error) {
            console.log(error?.msg)
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        fetchOverviewData();
    }, [date]);

    useEffect(() =>
    {
        fetchOverviewData();
    }, []);

    return (
        <div className="p-grid-md">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-grid-md pt-grid-md pb-grid-xl">
                <div className="text-neutral-low-dark transition-colors duration-fast ease-in">
                    <p className="pb-grid-xs text-md md:text-lg font-primary">
                        {strings("overview.welcome")}
                    </p>
                    <DateRange
                        loading={isLoading}
                        dateFrom={getDate(prefs?.lang, date, "YYYY-MM-DD")}
                        isPostLogin={true}
                        isFromOnly={true}
                        shouldShowTime={true}
                        time={"00:00:00"}
                    />
                </div>
                <div className="flex self-end items-center">
                    <DropdownMenu
                        list={constant.date_range}
                        labelKey={prefs?.lang === constant.lang.values[0] ? "label_ar" : "label_en"}
                        selectedValue={dateRangeValue}
                        setSelected={setDateRangeValue}
                        gap="gap-grid-sm sm:gap-grid-xl"
                        wrapperStyle="me-grid-sm"
                    />
                    <DateRangeByFilter
                        isDisabled={isLoading}
                        shouldFilterDateByDay={dateRangeValue?.value === "week" && isTuesday}
                        showMonthYearPicker={dateRangeValue?.value === "month"}
                        showQuarterYearPicker={dateRangeValue?.value === "quarter"}
                        showYearPicker={dateRangeValue?.value === "year"}
                        showTimeSelect={false}
                        inputClassName="ps-grid-sm bg-neutral-high-regular hover:bg-transparent text-neutral-low-darkest border-2 border-transparent hover:border-neutral-low-darkest transition-colors duration-fast ease-in"
                        value={date}
                        setDate={setDate}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-grid-md pb-grid-md">
                <DataCardWithIcon
                    loading={isLoading}
                    icon={"bookmark"}
                    iconViewBox="0 0 24 24"
                    label={strings("overview.copieSold")}
                    value={numberToNumberWithCommas(overviewData?.growth?.count_product_sku?.value)}
                />
                <DataCardWithIcon
                    loading={isLoading}
                    icon={"circleDollar"}
                    iconViewBox="0 0 22 22"
                    label={strings("overview.totalSales")}
                    value={priceToPriceWithCurrency(prefs?.currency, numberToNumberWithCommas(overviewData?.growth?.total_sales?.value))}
                />
                <DataCardWithIcon
                    loading={isLoading}
                    icon={"shop"}
                    iconViewBox="0 0 24 24"
                    label={strings("overview.participatingSellingCenters")}
                    value={numberToNumberWithCommas(overviewData?.growth?.count_outlet?.value)}
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-grid-md pb-grid-md">
                <DataCardPercentage
                    loading={isLoading}
                    number={`${overviewData?.growth?.count_product?.change ? overviewData?.growth?.count_product?.change : 0}%`}
                    label={strings("overview.publicationsGrowth")}
                    description={strings("overview.publicationsIssued")}
                />
                <DataCardPercentage
                    loading={isLoading}
                    number={`${overviewData?.growth?.count_contributor?.change ? overviewData?.growth?.count_contributor?.change : 0}%`}
                    label={strings("overview.authorsGrowth")}
                    description={strings("overview.authorsPublished")}
                />
                <DataCardPercentage
                    loading={isLoading}
                    number={`${overviewData?.growth?.count_producer?.change ? overviewData?.growth?.count_producer?.change : 0}%`}
                    label={strings("overview.publishersGrowth")}
                    description={strings("overview.publishersPublished")}
                />
                <DataCardPercentage
                    loading={isLoading}
                    number={`${overviewData?.growth?.count_outlet?.change ? overviewData?.growth?.count_outlet?.change : 0}%`}
                    label={strings("overview.sellingCentersGrowth")}
                    description={strings("overview.sellingCentersSold")}
                />
            </div>
            <div className="grid grid-rows-6 grid-flow-row md:grid-flow-col gap-grid-md pb-grid-md">
                <div className="p-grid-sm row-span-3 col-span-2 bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm">
                    <p className="text-md font-primary pt-grid-sm pb-grid-lg">
                        {strings("overview.salesPerDayOfWeek")}
                    </p>

                    <BarChartView
                        loading={isLoading}
                        data={overviewData?.chart?.sales_per_day_of_week}
                        labelKey="title_main"
                        valueKey="value"
                    />
                </div>
                <div className="p-grid-sm row-span-3 col-span-2 bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm">
                    <p className="text-md font-primary pt-grid-sm pb-grid-lg">
                        {strings("overview.salesPerMonthOfYear")}
                    </p>
                    <BarChartView
                        loading={isLoading}
                        data={overviewData?.chart?.sales_per_month_of_year}
                        labelKey="title_main"
                        valueKey="value"
                    />
                </div>
                <div className="p-grid-sm md:auto-cols-auto col-span-2 md:col-span-1 row-span-6 md:row-span-full bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm">
                    <p className="text-md font-primary pt-grid-sm pb-grid-lg">
                        {strings("overview.salesPerformanceByClassification")}
                    </p>
                    <PieChartView
                        loading={isLoading}
                        data={overviewData?.chart?.sales_per_category}
                        labelKey="title_main"
                        valueKey="value"
                        colors={constant?.chart_colors}
                    />
                </div>
            </div>
            <div className="p-grid-sm bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in text-center rounded-sm mb-grid-md">
                <p className="text-md font-primary pt-grid-sm">
                    {strings("overview.salesPerGeo")}
                </p>
                <p className="text-xs font-secondary pt-grid-sm pb-grid-lg">
                    {strings("overview.comparedToLastPeriod")}
                </p>
                <LineChartView
                    loading={isLoading}
                    data={overviewData?.chart?.sales_per_geo}
                    labelKey="title_main"
                    valueKey="value"
                />
            </div>
        </div>
    );
};

export default Overview;