import React, { createRef, useContext, useState } from "react";

import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Label from "../view/component/label";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import FileInput from "../view/component/fileInput";

export const FILE_TYPE = [
    {
        name: "اكسل (xls أو xlsx)",
        value: "excel"
    }
];

const UploadBulkOfProductsModal = () =>
{
    const { setActiveModal } = useContext(SessionContext);
    const booksFileRef = createRef(null);
    const [type, setType] = useState({});
    const [booksFile, setBooksFile] = useState({});

    const selectBooksFile = () =>
    {
        if (booksFileRef.current !== null)
        {
            booksFileRef.current.click();
        }
    };

    const downloadSample = () =>
    {
        setActiveModal(undefined);
    }

    const upload = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <div className="p-grid-md">
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.selectFileExtention")}/>
                <DropdownMenu
                    list={FILE_TYPE}
                    labelKey="name"
                    placeholder={strings("placeholder.selectFileExtention")}
                    selectedValue={type}
                    setSelected={setType}
                />
                <LineMsg type="error"/>
            </div>

            {Object.keys(type)?.length > 0 &&
                <div className="pb-grid-sm">
                    <Label label={strings("label.productsFile")}/>
                    <FileInput
                        fileRef={booksFileRef}
                        value={booksFile}
                        setValue={setBooksFile}
                        selectFile={selectBooksFile}
                    />
                    <LineMsg type="error"/>
                </div>
            }

            <div className="flex justify-between text-end pt-grid-lg">
                <Button
                    text={strings("button.downloadXLSSample")}
                    padding="py-2 px-grid-xl"
                    onClick={downloadSample}
                />
                <Button
                    text={strings("button.download")}
                    padding="py-2 px-grid-xl"
                    onClick={upload}
                />
            </div>
        </div>
    );
};

export default UploadBulkOfProductsModal;